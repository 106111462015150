import React from 'react';
import './Navbar.scss';
import { IoSearchOutline } from "react-icons/io5";
import bell from '../../../../assets/belIIcon.svg';
import set from '../../../../assets/navicon.svg';
import { IoIosArrowRoundForward } from "react-icons/io";
const Navbar = () => {
  return (
    <nav className='nav-cont'>
        <div className='nav-search-box'>
            <IoSearchOutline
            
            size={20}/> 
            <input 
            type="text" 
            placeholder='Search here...'
            />
        </div>

        <div className='nav-notif'>
            <div>
                <img src={bell} alt="icon" />
            </div>
            <div className='nav-notif-set'>
                <div>
                <img src={set} alt="icon" />
                </div>
                
                <p>Set Preferences</p>
                <IoIosArrowRoundForward
                color='#9CA0A5'
                size={20}
                />
            </div>
        </div>
    </nav>
  )
}

export default Navbar
