import React,{useState,useEffect} from 'react'
import './GreetingBox.scss';
import { FiRefreshCw } from "react-icons/fi";
import { CiCalendar } from "react-icons/ci";
import Spinner from '../../../Onboarding/Reusables/spinner/spinner';
import { useSelector } from "react-redux";
const GreetingBox = () => {

    const userData = useSelector(state => state.userLogin.userInfo);
    const firstName = userData.user.first_name;
    const [timeOfDay, setTimeOfDay] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [loading,setLoading]=useState(false)

    useEffect(() => {
        const updateGreeting = () => {
            const now = new Date();
            const hours = now.getHours();

            if (hours < 12) {
                setTimeOfDay('morning');
            } else if (hours < 18) {
                setTimeOfDay('afternoon');
            } else {
                setTimeOfDay('evening');
            }
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            setCurrentDate (now.toLocaleDateString('en-UK', options));
            
        };

        updateGreeting();
    }, []);
    

    return(
    <div className='greeting-box-cont'>
      <div className='greeting-box-cont-one'>
        <h4>
            Good {timeOfDay}, <span>{firstName}</span>🚀
        </h4>

        <div className='greeting-box-cont-rfr'>
        <p>Explore new and personalized content </p>

        <div> 
            {loading ?(
                
                <Spinner
                color={'#007EFF'}
                size={15}
                />
              
            ):(
                <>
                <FiRefreshCw 
                color='#007EFF'
                size={15}
                onClick={()=>setLoading(true)}
                /> 
                <p> Refresh</p> 
                </>
            )}
            
           
        </div>

        </div>
       
      </div>

      <div className='greeting-box-date'>
        <div className='greeting-box-cal' >
            <CiCalendar 
            size={17}
            />
        </div>
        <div className='greeting-box-day'>
            <p>Today’s Date</p>
            <h4>{currentDate}</h4>
        </div>
      </div>
    </div>
  )
}

export default GreetingBox
