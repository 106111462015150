import React, { useState,useEffect } from 'react';
import ContributorSidebar from '../Reusable/ContributorSidebar/ContributorSidebar';
import Navbar from '../../ConsumerDashboard/Reusable/Navbar/Navbar';
import GreetingBox from '../../ConsumerDashboard/Reusable/GreetingBox/GreetingBox';
import MyActivity from '../Reusable/MyActivity/MyActivity';
import GettingStarted from '../Reusable/GettingStarted/GettingStarted';
import NewsSeeAll from '../../ConsumerDashboard/Reusable/NewsSeeAll/NewsSeeAll';
import DashboardLoader from '../../ConsumerDashboard/Reusable/DashboardLoader/DashboardLoader';
import SeeAllBoxTwo from '../../ConsumerDashboard/Reusable/SeeAllBox/SeeAllBoxTwo';

const DiscoverContributor = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);

  return (
    <>
    {loading ? (

      <div>
      <DashboardLoader/>
      </div>
    ):(
    <div className='dashboard-main'>
        <div>
        <ContributorSidebar/>
        </div>
    
      <section>
    
         <Navbar/>
         <GreetingBox/>
         <MyActivity/>
         <GettingStarted/>
         <div style={{display:'flex',padding:'0em 2em 0em 2.5em',gap:'24px'}}>
          <div>
          <SeeAllBoxTwo
            headerTitle={"My drafts"}
            imageHeight={"91px"}
            imageWidth={"200px"}
            sliceNo={3}
            contHeight={"252px"}
          
            paddingMain={"0em 0em 1.5em 0em"}
          />
          <SeeAllBoxTwo
            headerTitle={"My Contributions"}
            imageHeight={"91px"}
            imageWidth={"200px"}
            sliceNo={3}
            contHeight={"252px"}
          
            paddingMain={"0em 0em 1.5em 0em"}
          />
          </div>

          <NewsSeeAll
            sliceNo={5}
            contHeight={"575px"}
          />
        </div>
      </section>
    </div>
    )}
    </>
  )
}

export default DiscoverContributor
