import React,{useState} from 'react';
import logo from '../../../../assets/logoSidebar.svg';
import './ConsumerSidebar.scss';
import discover1 from '../../../../assets/discoverIcon1.svg';
import discover2 from '../../../../assets/discoverIcon2.svg';
import news1 from '../../../../assets/newsIcon1.svg';
import news2 from '../../../../assets/newsIcon2.svg';
import data1 from '../../../../assets/dataIcon1.svg';
import data2 from '../../../../assets/dataIcon2.svg';
import visual1 from '../../../../assets/visualIcon1.svg';
import visual2 from '../../../../assets/visualIcon2.svg';
import report1 from '../../../../assets/reportIcon1.svg';
import report2 from '../../../../assets/reportIcon2.svg';
import setting from '../../../../assets/settings.svg';
import logoutIcon from '../../../../assets/sign-out.svg'
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";


const ConsumerSidebar = () => {
  
  const userData = useSelector(state => state.userLogin.userInfo);
  // const token = userData.token
  // const userEmail = userData.user.email;
  
  const firstName = userData.user.first_name;
  // const lastName = userData.user.last_name;
  // const fullName = firstName + " " + lastName;
    const [displayDrop,setDisplay]=useState()
    const { pathname } = useLocation();
    const toggleDisplay =()=>{
      setDisplay(!displayDrop)
    }
    const sideBarLinks = [
        {
          name: "Discover",
          path: "/dashboard-consumer",
          icon1: discover1 ,
          icon2: discover2
        },
        {
          name: "News",
          path: "/news-dsb",
          icon1: news1,
          icon2: news2
        },
        {
          name: "Datasets",
          path: "/dataset-dsb",
          icon1: data1,
          icon2: data2
        },
        {
          name: "Data Visualisations",
          path: "/data/vis-dsb",
          icon1: visual1,
          icon2: visual2
        },
        {
          name: "Reports",
          path: "/reports-dsb",
          icon1: report1,
          icon2: report2
        },
        
      ];
    const settingLinks = [
        {
          name: "Profile",
          path: "/consumer/profile",
        },
        {
          name: "Wallet",
          path: "/consumer/wallet",
        },
         
      ];

  const handleLogout=()=>{

    localStorage.clear();
    sessionStorage.clear();
    // navigate('/', { replace: true }); 
    window.location = "/";
  }
  return (
    <div className='consumer-sdb-cont'>

      <div className='sidebar-content'>

      
        <div className='consumer-sdb-cont-logo'>
          <img src={logo} alt="" />
        </div>

        {sideBarLinks.map((item, i) => (
          <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
          <div className={pathname===item.path ? 'consumer-sdb-cont-pg alt':'consumer-sdb-cont-pg'}>
              <div>
              <img 
              src={pathname === item.path ? item.icon2 : item.icon1}
              alt={item.name}
              />
              </div>
              
              <h4>{item.name}</h4>
          </div>
          </Link>
        ))}


        <div>
              <div 
              onClick={()=>toggleDisplay()}
              className='consumer-sdb-cont-sts'>
                  <div>
                      <div>
                          <img src={setting} alt="" />
                      </div>
                      <h4
                      
                      >Settings</h4>
                  </div>
                  {displayDrop ?
                  <IoIosArrowUp 
                  color='#9CA0A5' 
                  size={20}/>
                  :
                
                  <IoIosArrowDown
                  color='#9CA0A5' 
                  size={20}
                  />
                  }

              </div>
              
              {displayDrop ? (
                <>
                {settingLinks.map((item,i)=>(
                  <Link style={{textDecoration:"none"}}  to={item.path} key={i}>
                  <div 
                  // className={pathname===item.path ? 'consumer-sdb-cont-pg alt':'consumer-sdb-cont-prf'}
                  className='consumer-sdb-cont-prf'
                  >   
                      <h4>{item.name}</h4>
                  </div>
                  </Link>
                
                ))}
                </>
              ):(
                ""
              )}
              
              
            
        </div>
       </div>


       <div className='consumer-sdb-btm'>

    
       <div className='consumer-sdb-welcome'>

        <h4> Welcome </h4>
        <p> Get to know your Sonaroid Dashboard with our short walkthrough course.</p>

        <div className='consumer-sdb-wel-btn'>
          <p>Start Tutorial</p>
          <h4>Close</h4>
        </div>
       </div>

       <div className='consumer-sdb-logout'>
            <div>
              <div className='consumer-sdb-logout-img' >
                <img src="https://images.pexels.com/photos/709143/pexels-photo-709143.jpeg?auto=compress&cs=tinysrgb&w=600" alt="profile" />
              </div>

              <div className='consumer-sdb-logout-mail'>
                <h4>{firstName}</h4>
                {/* <p>{userEmail}</p> */}
              </div>
            </div>
            
            <img 
            onClick={()=>handleLogout()}
            src={logoutIcon} alt="icon"/>
       </div>
       </div>
      
    </div>
  )
}

export default ConsumerSidebar
