import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";


export default function Redirect() {

  const navigate = useNavigate();
  const [isLoggedIn] = useState(() =>
    JSON.parse(localStorage.getItem("userInfo")) || false
  );
 


  useEffect(() => {

    if (isLoggedIn) {
      if (isLoggedIn.user.account_type === "consumer") {
        navigate('/dashboard-consumer');
      } else if (isLoggedIn.user.account_type === "contributor") {
        navigate('/dashboard-contributor');
      } else if (isLoggedIn.user.account_type === "consumer_contributor") {
        navigate('/dashboard-contributor');
      } else if ((isLoggedIn.user.account_type === null) && (isLoggedIn.user.user_status === "onboarding")){
        navigate('/getstarted/flow-1');
      }
    } else {
      // Redirect to home if not logged in
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
    <div>

    </div>
  )
}