import React,{useState,useEffect} from "react";
import { Navigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/Onboarding/SignUp/SignUp';
import { useSelector } from "react-redux";
import EmailVerification from './components/Onboarding/EmailVerification/EmailVerification';
import MoreAboutYou from './components/Onboarding/MoreAboutYou/MoreAboutYou';
// import CompleteConsumer from './components/Onboarding/CompleteConsumer/CompleteConsumer';
// import CompleteContributor from './components/Onboarding/CompleteContributor/CompleteContributor';
import Login from './components/Authentication/Login/Login';
import ForgotPass from './components/Authentication/ForgotPass/ForgotPass';
import ResetPass from './components/Authentication/ResetPass/ResetPass';
import DiscoverConsumer from './components/ConsumerDashboard/DiscoverConsumer/DiscoverConsumer';
import ConsumerNews from './components/ConsumerDashboard/ConsumerNews/ConsumerNews';
import ConsumerData from './components/ConsumerDashboard/ConsumerData/ConsumerData';
import ConsumerDataVisual from './components/ConsumerDashboard/ConsumerDataVisual/ConsumerDataVisual';
import ConsumerReport from './components/ConsumerDashboard/ConsumerReport/ConsumerReport';
import ConsumerProfile from './components/ConsumerDashboard/ConsumerProfile/ConsumerProfile';
import ConsumerWallet from './components/ConsumerDashboard/ConsumerWallet/ConsumerWallet';
import ScrollToTop from './Scroll';
import Redirect from './components/Redirect';
import ContributorSidebar from "./components/ContributorDashoard/Reusable/ContributorSidebar/ContributorSidebar";
import DiscoverContributor from "./components/ContributorDashoard/DiscoverContributor/DiscoverContributor";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(null);
  const [verified,setVerified]=useState(null)
  const userData = useSelector((state) => state.userLogin.userInfo.user);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setIsLoggedIn(userInfo && Object.keys(userInfo).length > 0);
    
    if (userInfo) {
      const accountType = userData.account_type;
      const accountVerified = userData.is_verified;
      if (accountType === "consumer" && accountVerified === true) {
        setRole('consumer');
        setVerified(true);
      } else if (accountType === "contributor" && accountVerified === true) {
        setRole('contributor');
        setVerified(true);
      } else if (accountType === "consumer_contributor" && accountVerified === true) {
        setRole('hybrid');
        setVerified(true);
      } else if (accountType === null && accountVerified === true) {
        setRole('onboard');
        setVerified(true);
      } 
       else if (accountType === null && accountVerified === false) {
        setRole('onboard');
        setVerified(false);

      } else {
        setRole(null);
       
      }
    } else {
      setRole(null);
    }
  }, [userData]);

  console.log(role, isLoggedIn);
  
  return (
    <Router>
       <ScrollToTop/>
      <Routes>
      {role === 'consumer' && (
        <>
         <Route path="*" element={<Navigate to="/dashboard-consumer" />} />
        <Route path="/" element={<DiscoverConsumer />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/dashboard-consumer" element={<DiscoverConsumer />} />
        <Route path="/news-dsb" element={<ConsumerNews />} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/reports-dsb" element={<ConsumerReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        </>

      )}
      {role === 'contributor' && (
        <>
         <Route path="*" element={<Navigate to="/dashboard-contributor" />} />
        <Route path="/" element={<ContributorSidebar />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/dashboard-contributor" element={<DiscoverContributor />} />
        </>

      )}
      {role === 'hybrid' && (
        <>
         <Route path="*" element={<Navigate to="/dashboard-contributor" />} />
        <Route path="/" element={<DiscoverContributor />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/dashboard-consumer" element={<DiscoverConsumer />} />
        <Route path="/news-dsb" element={<ConsumerNews />} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/reports-dsb" element={<ConsumerReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/dashboard-contributor" element={<DiscoverContributor />} />
        </>

      )}
      {(role === 'onboard') && (verified === true) &&(
        <>
         <Route path="*" element={<Navigate to="/getstarted/flow-1" />} />
         <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        </>

      )}
      {(role === 'onboard') && (verified === false) &&(
        <>
         <Route path="*" element={<Navigate to="/verifymail" />} />
         <Route path="/" element={<Navigate to="/verifymail" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        </>

      )}
      {!isLoggedIn && (
        <>
        <Route path="*" element={<Navigate to="/Login" />} />
         <Route path="/" element={<Redirect />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPass />} />
          <Route path="/resetPassword" element={<ResetPass />} />
          <Route path="/login" element={<Login />} /> 
        </>
      )}
        {/* <Route path="/" element={<Redirect />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/dashboard-consumer" element={<DiscoverConsumer />} />
        <Route path="/news-dsb" element={<ConsumerNews />} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/reports-dsb" element={<ConsumerReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/login" element={<Login />} /> */}

      </Routes>
  </Router>
  );
}

export default App;
