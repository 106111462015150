import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import jbk from '../../../../assets/jbk3.png';
import './SeeAllBox.scss';
import report2 from '../../../../assets/map-green.svg'
const SeeAllBoxTwo = ({
    headerTitle,
    imageHeight,
    imageWidth,
    sliceNo,
    contHeight,
    contWidth,
    paddingMain
}) => {

    
    const jump =[
        {
            para:"Sun meets sea: Floating PV to play a key role in...",
            img1:jbk,
            country:"Nigeria"
            
        },
        {
            para:"Sun meets sea: Floating PV to play a key role in...",
            img1:jbk,
            country:"Nigeria"
        },
        {
            para:"Sun meets sea: Floating PV to play a key role in...",
            img1:jbk,
            country:"Nigeria"
            
        },
        {
            para:"Sun meets sea: Floating PV to play a key role in...",
            img1:jbk,
            country:"Nigeria"
        },
        {
            para:"African Oil and Gas Supply Chain and ",
            img1:jbk,
            
        },
        {
            para:"African Oil and Gas Supply",
            img1:jbk,
            
        },
        {
            para:"African Oil and ",
            img1:jbk,
            
        },
        {
            para:"Anigeriam erigbnoekn nreoneognog O",
            img1:jbk,
            
        },
        {
            para:"Report finds more than 200 projects could benefit from  Opportunities in ",
            img1:jbk,
            
        },
        {
            para:"Report finds more than 200 projects could benefit from subsea boosting",
            img1:jbk,
            
        },
        {
            para:"Report finds more than 200 projects could benefit",
            img1:jbk,
            
        }
    ]

const slicedItems = jump.slice(0, sliceNo);

const useStyle1={
    width: imageWidth,
    height: imageHeight
}
const useStyle2={
    height: contHeight,
    width:contWidth
}
const useStyle3={ 
    width:imageWidth
}
const useStyle4={ 
    padding:paddingMain
}

  return (
    <div style={useStyle4} className='see-all-box-main'>
      <div style={useStyle2} className='see-all-box-cont'>

            <div className='see-all-box-header'>
                <h4>{headerTitle}</h4>
                <div className='see-all-box-arrow'>
                <p>See all</p>  
                <IoIosArrowRoundForward
                    size={24}
                    />
                </div>
            </div>

            <div className='see-all-box-two'>
                {slicedItems.map((item,index)=>(

                    <div key={index} style={useStyle3} className='see-all-box-two-box'>

                        <div  style={useStyle1} className='see-all-box-two-img'>
                            <img src={item.img1} alt="" />
                        </div>

                        <div className='trending-two-type'>
                        <img src={report2} alt="imgI" />
                            <p>VISUALIZATION</p>
                        </div>

                        <div className='see-all-box-two-title'>
                            <p>{item.para}</p>
                        </div>

                    
                        <div className='see-all-box-updated'>
                            <p>Updated: 22/09/2024</p>
                        </div>
                    </div>
                ))}
            </div>
      </div>
    </div>
  )
}

export default SeeAllBoxTwo
